// Imports
// ------
import React from 'react';
import { Row, Column } from '@waffl';
import { headerSize } from '@states/headerSize';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, Date, Title } from './styles';

// Component
// ------
const Hero = ({ date, title }) => (
	<Jacket bg pad>
		<Row isExpanded style={{ paddingTop: headerSize.height }}>
			<Column small={12} medium={10} pushOnMedium={1} mpad>
				{date && <Date>Effective from {date}</Date>}
				<Title data-splitting>{title}</Title>
			</Column>
		</Row>
	</Jacket>
);

export default observer(Hero);
