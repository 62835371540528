// Imports
// ------
import React, { useEffect } from 'react';
import Hero from '@parts/Legal/Hero';
import Content from '@parts/Legal/Content';
import Footer from '@parts/Footer';
import { action } from 'mobx';
import { headerState } from '@states/header';

import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';

// Component
// ------
const LegalPage = ({ data, transitionStatus, pageContext }) => {
	useEffect(() => {
		const setHeader = action(() => {
			headerState.noBg = true;
		});

		setHeader();
	}, []);

	useEffect(() => {
		const Splitting = require('splitting');

		Splitting({
			target: '[data-splitting]',
			by: 'chars',
			key: null,
		});
	}, []);

	return (
		<>
			<Hero date={pageContext.date} title={pageContext.pageTitle} />
			<Content wysiwyg={pageContext.content} />
			<Footer std />
		</>
	);
};

export default LegalPage;
